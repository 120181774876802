// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuality-index-jsx": () => import("./../../../src/pages/aktuality/index.jsx" /* webpackChunkName: "component---src-pages-aktuality-index-jsx" */),
  "component---src-pages-galerie-index-jsx": () => import("./../../../src/pages/galerie/index.jsx" /* webpackChunkName: "component---src-pages-galerie-index-jsx" */),
  "component---src-pages-galerie-leto-2020-index-jsx": () => import("./../../../src/pages/galerie/leto/2020/index.jsx" /* webpackChunkName: "component---src-pages-galerie-leto-2020-index-jsx" */),
  "component---src-pages-galerie-leto-2021-index-jsx": () => import("./../../../src/pages/galerie/leto/2021/index.jsx" /* webpackChunkName: "component---src-pages-galerie-leto-2021-index-jsx" */),
  "component---src-pages-galerie-leto-2022-index-jsx": () => import("./../../../src/pages/galerie/leto/2022/index.jsx" /* webpackChunkName: "component---src-pages-galerie-leto-2022-index-jsx" */),
  "component---src-pages-galerie-leto-2023-index-jsx": () => import("./../../../src/pages/galerie/leto/2023/index.jsx" /* webpackChunkName: "component---src-pages-galerie-leto-2023-index-jsx" */),
  "component---src-pages-galerie-leto-2024-index-jsx": () => import("./../../../src/pages/galerie/leto/2024/index.jsx" /* webpackChunkName: "component---src-pages-galerie-leto-2024-index-jsx" */),
  "component---src-pages-galerie-velikonoce-2023-index-jsx": () => import("./../../../src/pages/galerie/velikonoce/2023/index.jsx" /* webpackChunkName: "component---src-pages-galerie-velikonoce-2023-index-jsx" */),
  "component---src-pages-galerie-velikonoce-2024-index-jsx": () => import("./../../../src/pages/galerie/velikonoce/2024/index.jsx" /* webpackChunkName: "component---src-pages-galerie-velikonoce-2024-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-informace-index-jsx": () => import("./../../../src/pages/informace/index.jsx" /* webpackChunkName: "component---src-pages-informace-index-jsx" */),
  "component---src-pages-kontakt-index-jsx": () => import("./../../../src/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-pages-kontakt-index-jsx" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-article-post-jsx": () => import("./../../../src/templates/article-post.jsx" /* webpackChunkName: "component---src-templates-article-post-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */),
  "component---src-templates-velikonoce-post-jsx": () => import("./../../../src/templates/velikonoce-post.jsx" /* webpackChunkName: "component---src-templates-velikonoce-post-jsx" */),
  "component---src-templates-vylety-post-jsx": () => import("./../../../src/templates/vylety-post.jsx" /* webpackChunkName: "component---src-templates-vylety-post-jsx" */)
}

